.banners {
  padding-top: 0;
  padding-bottom: 0;
}

.banner {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &_without__link {
    cursor: pointer;
  }
}